<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <RoleForm :data="item" :permissions="permissions" @save="save" />
    </v-card>
  </v-container>
</template>

<script>
import RoleForm from "./RoleForm";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "RoleEditor",
  components: {
    RoleForm,
  },
  data() {
    return {
      item: {},
      permissions: [],
    };
  },
  mounted() {
    if (this.editMode) {
      this.setLoadingState(this.$t("messages.loadingRole"));
    } else {
      this.setLoadingState(this.$t("messages.initializingRole"));
    }

    this.loadPermissions().then((data) => {
      this.permissions = data;
    });

    if (this.editMode) {
      this.item = this.getRoleById(this.idParam);
      if (!this.item) {
        this.loadRoles().then(() => {
          this.item = this.getRoleById(this.idParam);
          this.refreshBreadcrumb();
        });
      } else {
        this.refreshBreadcrumb();
      }
    } else {
      this.refreshBreadcrumb();
    }
  },
  computed: {
    ...mapGetters("roles", ["getRoleById"]),

    editMode() {
      return this.idParam > 0;
    },
    idParam() {
      return parseInt(this.$route.params.id);
    },
  },
  methods: {
    ...mapActions("roles", ["loadRoles", "saveRole", "loadPermissions"]),
    ...mapActions("ui", [
      "setBreadcrumbs",
      "setLoadingState",
      "clearLoadingState",
    ]),

    refreshBreadcrumb() {
      const updatedBreadcrumbs = this.$route.meta.breadcrumb.map((item) => {
        if (item.dynamic) {
          return { ...item, name: this.item.name };
        }
        return item;
      });
      this.setBreadcrumbs(updatedBreadcrumbs);
      this.clearLoadingState();
    },
    save(params) {
      this.saveRole(params);
      this.$router.go(-1);
    },
  },
};
</script>
