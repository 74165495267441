<template>
  <v-card flat>
    <v-toolbar flat dense class="grey lighten-5">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-divider vertical inset class="d-none d-md-flex mx-2" />
      <v-toolbar-title class="py-3 px-0">
        <span v-if="editMode">
          {{ $t("role.edit") }}
        </span>
        <span v-if="!editMode">
          {{ $t("role.new") }}
        </span>
      </v-toolbar-title>
      <v-spacer />
      <v-chip small label v-if="isReadOnly || item.name === 'Administrator'" color="yellow">
        <v-avatar left>
          <v-icon small>mdi-alert</v-icon>
        </v-avatar>
        {{ $t("general.readonly") }}
      </v-chip>
      <v-divider vertical inset class="d-none d-md-flex mx-2" />
      <v-btn
        :disabled="isReadOnly || item.name === 'Administrator' || !valid"
        :dark="!isReadOnly && valid"
        small
        text
        color="success"
        class="mx-2"
        @click="save"
      >
        <v-icon>mdi-content-save</v-icon>
        <span class="text--secondary">{{ $t("general.save") }}</span>
      </v-btn>
    </v-toolbar>

    <v-card-text class="overflow-y-auto px-0 py-0" :style="viewHeight()">
      <v-form ref="form" v-model="valid" class="py-5 px-5">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="item.name"
              :counter="200"
              :rules="rules.name"
              :label="$t('general.name')"
              required
              dense
              :readonly="isReadOnly || ['Administrator', 'Resource Manager', 'AMRF Administrator', 'User'].includes(
                  item.name
                )
              "
            ></v-text-field>
            <v-text-field
              v-model="item.nameUk"
              :counter="100"
              :rules="rules.nameUk"
              :label="$t('general.nameUk')"
              required
              dense
              :readonly="isReadOnly ||
                ['Administrator', 'Resource Manager', 'AMRF Administrator', 'User'].includes(
                  item.name
                )
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-card tile>
              <v-sheet tile height="40" color="secondary" class="pa-3">
                <p class="v-label theme--light white--text">
                  {{ $t("role.permissions") }}
                </p>
              </v-sheet>
              <v-list dense v-model="permissions">
                <v-list-item-group color="primary">
                  <div v-for="(prm, i) in permissions" :key="i">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="prm.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="prm.nameUk"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch
                          v-model="selectedPermissions"
                          :value="prm.id"
                          :readonly="isReadOnly || item.name === 'Administrator'"
                        />
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </div>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>

        <br />
        <v-divider />
        <v-btn
          :disabled="isReadOnly || item.name === 'Administrator' || !valid"
          :dark="valid && item.name !== 'Administrator'"
          color="success"
          class="mr-4"
          tile
          depressed
          @click="save"
        >
          <v-icon>mdi-content-save</v-icon>
          {{ $t("general.save") }}
        </v-btn>

        <v-btn
          :disabled="isReadOnly || item.name === 'Administrator'"
          color="primary"
          tile
          depressed
          @click="reset"
        >
          <v-icon>mdi-undo-variant</v-icon>
          {{ $t("general.undo") }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "RoleForm",
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    permissions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      item: {
        id: 0,
        name: "",
        nameUk: "",
      },
      selectedPermissions: [],
      valid: false,
      originalState: { id: 0 },
      rules: {
        name: [(v) => !!v || "Name is required"],
        nameUk: [(v) => !!v || "Ukranian name is required"],
      },
    };
  },
  watch: {
    data: {
      handler: function(n) {
        if (n) {
          this.item = { ...n };
          this.selectedPermissions = n.permissions
            ? n.permissions.map((m) => m.id)
            : [];
          this.originalState = { ...n };
        }
      },
    },
  },
  computed: {
    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },

    idParam() {
      return parseInt(this.$route.params.id);
    },
    editMode() {
      return this.idParam > 0;
    },
  },
  methods: {
    save() {
      const item = { ...this.item, permissions: this.selectedPermissions };
      this.$emit("save", { item, editMode: this.editMode });
    },
    reset() {
      this.permissions = [];
      this.item = { ...this.originalState };
    },
  },
};
</script>

<style scoped>
.text-deleted {
  text-decoration: line-through;
}
</style>
